<template>
  <RewardReviewOrderLayout
    :id="id"
    :balance="balance"
    :color="color"
    :cost="cost"
    :image="image"
    :isLoading="isLoading"
    :title="title"
    :type="type"
    @onBuyReward="handleBuyReward"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RewardReviewOrderLayout from './layout';

export default {
  name: 'RewardReviewOrder',
  components: {
    RewardReviewOrderLayout,
  },

  data() {
    return {
      isLoading: false,
    };
  },
 
  props: {
    id: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#FB4DAD',
    },
    cost: {
      type: [String, Number],
      required: true,
    },
    image: {
      type: String,
      default: 'https://img.dibbs.io/frac_pack_std.png',
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState('rewards', ['balance']),
  },

  methods: {
    ...mapActions('rewards', ['rewardsShopBuy']),

    async handleBuyReward(shopItem) {
      try {
        this.isLoading = true;
        const id = await this.rewardsShopBuy(shopItem);
        this.showModal('RewardOrderConfirmed', { ...shopItem, id });
      } catch(err) {
        await this.$store.dispatch('ui/showError', err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
</style>
