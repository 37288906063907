<template>
  <div class="review-order">
    <FracPackImage
      :image="image"
      :imageHeight="300"
      :imageWidth="195"
      :glowColor="color"
      :glowHeight="150"
      :glowWidth="150"
      :glowBlur="40"
    />

    <h1 class="headline-medium w-full mt-s40"> {{ $t('trade.review_order') }} </h1>
      
    <div class="flex justify-between items-center w-full mt-s24">
      <p class="body-text-large">{{ $t('rewards.gems_balance') }}:</p>
      <p class="subheadline-small">{{ numberFormat(balance, 0) }}</p>
    </div>

    <div class="flex justify-between items-center w-full mt-s12">
      <p class="body-text-large">{{ title }}:</p>
      <p class="subheadline-small">{{ numberFormat(cost, 0) }}</p>
    </div>

    <hr class="w-full border-border my-s8" />

    <div class="flex justify-between items-center w-full">
      <p class="review-order-total-purchase">{{ $t('trade.total_purchase') }}:</p>
      <p class="review-order-cost">{{ numberFormat(cost, 0) }}</p>
    </div>

    <ButtonV2
      @onClick="buyReward"
      class="mt-s40"
      :label="$tc('confirm_purchase')"
      testId="btn-confirmPurchase"
      :loading="isLoading"
      size="medium"
      wide
    />
  </div>
</template>

<script>
import ButtonV2 from '@/stories/misc/ButtonV2.vue';
import { FracPackImage } from '@/components/fracpack';

export default {
  name: 'RewardReviewOrderLayout',
  components: {
    ButtonV2,
    FracPackImage,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    balance: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: '#FB4DAD',
    },
    cost: {
      type: [String, Number],
      required: true,
    },
    image: {
      type: String,
      default: 'https://img.dibbs.io/frac_pack_std.png',
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    buyReward() {
      this.$emit('onBuyReward', { ...this.$props });
    },
  },
};
</script>

<style scoped>
.review-order {
  @apply flex flex-col justify-center items-center mt-s20 mx-s12;
  min-width: 335px;
}

.review-order-total-purchase {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.review-order-cost {
  font-size: 20px;
  line-height: 20px;
  font-weight: 700;  
}
</style>
